import React from 'react';
import { colors } from 'css/variables';

const CloseIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="9.5"
        cy="9.5"
        r="9.5"
        fill="#7728F9"
        fillRule="nonzero"
        strokeWidth="2"
      />
      <path
        stroke={colors.white}
        d="M5.5 0L5.5 11M0 5.5L11 5.5"
        transform="rotate(45 2.672 12.328)"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default CloseIcon;
