import React from 'react';
import css from './confirmation-modal.module.css';
import Modal from 'react-responsive-modal';
import Button from 'components/common/button';
import CloseIcon from 'static/svgs/icon/close-icon';

interface ConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
}): JSX.Element => {
  return (
    <div className={css.modalContainer}>
      <Modal
        open={isOpen}
        onClose={onCancel}
        center
        closeIcon={<CloseIcon />}
        classNames={{
          overlay: css.confirmationModalOverlay,
          modal: css.confirmationModal,
        }}
      >
        <div className={css.confirmationModalContent}>
          <div className={css.confirmationTitleCenter}>
            Are you sure you want to delete this list?
          </div>
          <div>
            <div>This action cannot be undone.</div>
            <div className={css.deleteConfirmationWrapper}>
              <Button
                fullWidth
                type="lighter"
                onClick={onCancel}
                style={{ marginRight: 16 }}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                onClick={onConfirm}
                type="warning"
                size="medium"
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
